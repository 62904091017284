import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';
import ReactTextTransition, { presets } from 'react-text-transition';

import { Grid, Typography, useTheme } from '@material-ui/core';

export const CtaSection = ({ header, powerStatement, blurbs }) => {
	// const [rotationWords] = useState([
	// 	'The first.',
	// 	'The future.',
	// 	'The real deal in lawn software.',
	// ]);
	// const [selectedWord, setSelectedWord] = useState(0);

	// useEffect(() => {
	// 	let rotationId;

	// 	rotationId = setInterval(() => {
	// 		setSelectedWord((selected) =>
	// 			selected < rotationWords.length - 1 ? selected + 1 : 0
	// 		);
	// 	}, 4000);

	// 	return () => clearInterval(rotationId);
	// }, [selectedWord]);

	// const Rotation = () => (
	// 	<Typography
	// 		variant='h3'
	// 		color='primary'
	// 		style={{ marginTop: '2rem', fontWeight: 700 }}>
	// 		<ReactTextTransition direction='down' springConfig={presets.gentle}>
	// 			{rotationWords[selectedWord]}
	// 		</ReactTextTransition>
	// 	</Typography>
	// );
	// const theme = useTheme();
  return (
		<Grid container direction='column'>
			<Grid item>
				<PortableText
					content={header}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h3'
								color='primary'
								style={{ marginBottom: '2rem', fontWeight: 700 }}>
								{children}
							</Typography>
						),
					}}
				/>
			</Grid>

			{blurbs.map((blurb, index) => (
				<Grid
					item
					key={index}
					container
					direction='row'
					alignItems='flex-start'
					style={{ marginBottom: '2rem', flexWrap: 'nowrap' }}>
					<FontAwesomeIcon
						icon={['fas', blurb.icon]}
						size='2x'
						style={{
							color: blurb.accentColor.hexValue ?? '#002D5C',
							// height: '40px',
							// width: '40px',
							// border: `1px solid #E5EAF4`,
							// borderRadius: '8px',
							// background: '#E5EAF4',
							// padding: '8px',
							textAlign: 'center',
							marginRight: '20px',
						}}
					/>
					<Typography
						variant='h6'
						color='primary'
						style={{ fontFamily: 'Roboto', fontWeight: 400 }}>
						{blurb.title}
					</Typography>
				</Grid>
			))}
			<Grid item>
				<PortableText
					content={powerStatement}
					serializers={{
						h3: ({ children }) => (
							<Typography
								variant='h3'
								color='primary'
								style={{ marginTop: '1rem', fontWeight: 700 }}>
								{children}
							</Typography>
						),
            strong: ({ children }) => (
							<Typography
								variant='h3'
								style={{ fontWeight: 700, color: '#3FA529' }}>
								{children}
							</Typography>
						),
					}}
				/>
				{/* <Rotation /> */}
			</Grid>
		</Grid>
	);
};
